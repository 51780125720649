@use "src/assets/styles/mixins" as mixins;
@use "src/assets/styles/variables" as variables;
.wrapper {
    position: relative;
}
.imgBox {
    display: flex;
    aspect-ratio: 1/1;
    width: 10rem;
    border: 1px solid variables.$border-gray-1;
}
.previewImg {
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
}

.deleteBtn:global(.MuiButton-root) {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    min-width: 3.2rem;
    padding: 0.5rem;
    border: 1px solid variables.$border-gray-1;
    border-radius: 50%;
    background-color: variables.$background-paper;
    :global(.MuiButton-icon) {
        margin: 0;
    }
    &:hover {
        animation: animationRoll 0.3s 1 linear normal;
        background-color: variables.$background-paper;
    }
}
